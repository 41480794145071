import React from 'react';
import tw from 'twin.macro';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import { CSSTransition } from 'react-transition-group';
import Img, { FluidObject } from 'gatsby-image';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

interface HeaderProps {
  logo?: FluidObject;
  pathname?: string;
  fixed?: boolean;
  headbump?: any;
  hideHeadbump?: boolean;
}

interface HeaderState {
  layananActive?: boolean;
  peraturanActive?: boolean;
  eventActive?: boolean;
  sidemenuActive?: boolean;
  offset?: number;
}

class Header extends React.Component<HeaderProps, HeaderState> {
  constructor(props: HeaderProps) {
    super(props);
    this.state = {
      layananActive: false,
      peraturanActive: false,
      eventActive: false,
      sidemenuActive: false,
      offset: 0,
    };
  }

  handleScroll() {
    if (typeof window !== 'undefined') {
      window.onscroll = () => {
        this.setState({
          offset: window.scrollY,
        });
      };
    }
  }

  resetMenu() {
    this.setState({
      layananActive: false,
      peraturanActive: false,
      eventActive: false,
      sidemenuActive: false,
    });
  }

  render() {
    this.handleScroll();

    const headbump = this.props.headbump;
    const offsetSize = headbump.eventLogo && !this.props.hideHeadbump ? 100 : 48;

    let tagProduct = 'product-item-container';
    if (typeof window !== 'undefined') {
      if (window.innerWidth < 1024) {
        tagProduct = 'paket-express';
      }
    }
    return (
      <header
        css={css`
          /* background: transparent; */
          position: relative;
          ${headbump.backgroundColor ? `background: ${headbump.backgroundColor};` : tw`bg-orange`}
          .dropdown-enter {
            opacity: 0;
            transform: translateY(-20px);
          }
          .dropdown-enter-active {
            opacity: 1;
            transform: translateY(0);
            transition: opacity 0.5s cubic-bezier(0.16, 1, 0.3, 1),
              transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
          }
          .dropdown-exit {
            opacity: 1;
          }
          .dropdown-exit-active {
            opacity: 0;
            transform: translateY(-20px);
            transition: opacity 0.1s cubic-bezier(0.16, 1, 0.3, 1),
              transform 0.1s cubic-bezier(0.16, 1, 0.3, 1);
          }
          .headbump {
            ${this.props.hideHeadbump ? tw`hidden` : ''}
          }
        `}
      >
        <div
          tw="container flex justify-center items-center w-full"
          className="headbump"
          css={css`
            height: 48px;
            ${headbump.eventLogo ? tw`justify-between` : tw`justify-center`}
            ${headbump.eventLogo ? tw`lg:px-32` : ''}
            @media (max-width: 1024px) {
              ${headbump.eventLogo ? tw`block` : tw`flex`}
              position: relative;
              height: ${offsetSize}px;
            }
          `}
        >
          {headbump.eventLogo ? (
            <div
              css={css`
                @media (max-width: 1024px) {
                  padding-top: 6px;
                  padding-bottom: 6px;
                  ${tw`mx-4`}
                }
              `}
            >
              <Img
                alt={headbump?.hyperlinkText || 'event'}
                fluid={headbump.eventLogo.fluid as FluidObject}
                css={css`
                  width: 141px;
                  height: 40px;
                `}
              />
            </div>
          ) : null}
          <div>
            <div
              tw="text-h4 m-0 p-0 font-extrabold font-gilroy"
              css={css`
                ${headbump.textColor ? `color: ${headbump.textColor};` : tw`text-blue-black`}
                @media (max-width: 1024px) {
                  ${tw`mx-4`}
                }
              `}
            >
              {headbump.text || 'Ingin mendirikan PT hanya dalam waktu 2 hari?'}{' '}
              <AnchorLink
                tw="font-extrabold text-blue-lapis hover:text-yellow-bright underline font-normal"
                css={css`
                  ${headbump.hyperlinkColor
                    ? `color: ${headbump.hyperlinkColor};`
                    : tw`text-blue-lapis`}
                `}
                to={(headbump.hyperlinkUrl || '/layanan/paket-easy-business') + '#' + tagProduct}
                className="gtm-headbump"
              >
                {headbump.hyperlinkText || 'Dapatkan Sekarang Juga!'}
              </AnchorLink>
            </div>
          </div>
        </div>
        <div
          tw="w-full flex items-center text-white"
          css={css`
            position: absolute;
            z-index: 8888;
            height: 69px;
            transition: background-color 300ms, top 300ms;
            @media (min-width: 1024px) {
              ${this.state?.layananActive ||
              this.state?.peraturanActive ||
              this.state?.eventActive ||
              this.props?.fixed ||
              this.state?.offset > 48
                ? tw`bg-blue-black`
                : tw`bg-transparent`}
              &:hover {
                ${tw`bg-blue-black`}
              }
              ${this.state?.offset > 48 ? 'top: 0px; position: fixed;' : 'position: absolute;'}
            }

            @media (max-width: 1024px) {
              ${tw`bg-blue-black`}
              ${this.state?.offset > offsetSize
                ? 'top: 0px; position: fixed;'
                : `position: absolute;`}
            }
          `}
        >
          <div tw="container mx-4 lg:mx-auto flex items-center w-full">
            <div
              tw="mr-8 text-xl cursor-pointer lg:hidden"
              className={this.state?.sidemenuActive ? 'icon icon-cross' : 'icon icon-menu'}
              onClick={() => {
                this.setState({
                  sidemenuActive: !this.state?.sidemenuActive,
                  layananActive: false,
                });
              }}
            ></div>
            <Link tw="lg:ml-32" to="/" aria-label="beranda">
              <Img
                alt="Easybiz"
                fluid={this.props.logo as FluidObject}
                css={css`
                  margin-right: 40px;
                  position: relative;
                  width: 68px;
                  bottom: 5px;
                  @media (min-width: 1024px) {
                    bottom: 0px;
                    width: 86px;
                  }
                `}
              />
            </Link>
            <nav
              tw="flex items-center"
              css={css`
                .subnav {
                  position: relative;
                  & > a {
                    padding: 24px 0;
                    padding-right: 40px;
                  }
                }
                ul {
                  ${tw`bg-blue-black`}
                  position: absolute;
                  left: -16px;
                  top: 43px;
                  margin: 0;
                  padding: 0;
                  padding-bottom: 16px;
                  li {
                    list-style: none;
                    margin: 0;
                    a {
                      display: block;
                      margin: 0;
                      padding: 6px 16px;
                      min-width: 350px;
                      transition: background-color 300ms;
                      ${tw`bg-blue-black`}
                      &:hover {
                        ${tw`bg-blue-marine`}
                      }
                    }
                  }
                }
                a {
                  cursor: pointer;
                  color: #ffffff;
                  text-decoration: none;
                  font-size: 16px;
                  padding: 24px 0;
                  padding-right: 40px;
                  &:hover,
                  &.active {
                    color: #ffffff;
                  }
                  .icon {
                    position: relative;
                    top: 0.2em;
                  }
                }
              `}
            >
              <div
                className="subnav"
                onClick={() => {
                  this.setState({
                    layananActive: !this.state?.layananActive,
                    peraturanActive: false,
                    eventActive: false,
                    sidemenuActive: false,
                  });
                }}
                onMouseOver={() => {
                  if (window.innerWidth < 1024) {
                    return;
                  }
                  this.setState({
                    layananActive: true,
                    peraturanActive: false,
                    eventActive: false,
                    sidemenuActive: false,
                  });
                }}
                onMouseLeave={() => {
                  this.setState({
                    layananActive: false,
                    peraturanActive: false,
                    eventActive: false,
                    sidemenuActive: false,
                  });
                }}
              >
                <a href="/" onClick={e => e.preventDefault()}>
                  Layanan{' '}
                  <span
                    className={
                      this.state?.layananActive
                        ? 'icon icon-arrow-drop-up'
                        : 'icon icon-arrow-drop-down'
                    }
                  ></span>
                </a>
                <CSSTransition
                  in={this.state?.layananActive}
                  timeout={300}
                  classNames="dropdown"
                  unmountOnExit
                >
                  <ul
                    key={this.state?.layananActive?.toString()}
                    css={css`
                      @media (max-width: 1024px) {
                        width: calc(100vw - 32px);
                        left: -170px !important;
                      }
                    `}
                  >
                    <li>
                      <Link to="/layanan/paket-easy-business">Pendirian PT</Link>
                    </li>
                    <li>
                      <Link to="/layanan/paket-pendirian-pt-perorangan">
                        Pendirian PT Perorangan
                      </Link>
                    </li>
                    <li>
                      <Link to="/layanan/paket-pendirian-cv">Pendirian CV</Link>
                    </li>
                    <li>
                      <Link to="/layanan/paket-izin-usaha">Layanan Izin Usaha</Link>
                    </li>
                    <li>
                      <Link to="/layanan/paket-oss">Layanan OSS RBA</Link>
                    </li>
                    <li>
                      <a href="https://www.easybiz.id/layanan/paket-iumk/">Paket Izin UMK</a>
                    </li>
                    <li>
                      <Link to="/layanan/pendirian-yayasan-atau-perkumpulan">Pendirian Yayasan atau Badan Usaha Lain</Link>
                    </li>
                    <li>
                      <Link to="/layanan/paket-lkpm">Layanan LKPM</Link>
                    </li>
                    <li>
                      <Link to="/layanan/paket-pma">Penanaman Modal Asing</Link>
                    </li>
                    <li>
                      <Link to="/layanan/pendaftaran-merek">Pendaftaran Merek</Link>
                    </li>
                    <li>
                      <Link to="/layanan/paket-virtual-office">Layanan Virtual Office</Link>
                    </li>
                  </ul>
                </CSSTransition>
              </div>

              {/* navigation drawer */}

              <CSSTransition
                in={this.state?.sidemenuActive}
                timeout={300}
                classNames="fade"
                unmountOnExit
              >
                <div
                  css={css`
                    background: rgba(0, 0, 0, 0.2);
                    position: absolute;
                    top: 68px;
                    height: 200vh;
                    width: 100vw;
                    left: 0;
                  `}
                  onClick={() => {
                    this.setState({
                      layananActive: false,
                      peraturanActive: false,
                      eventActive: false,
                      sidemenuActive: false,
                    });
                  }}
                ></div>
              </CSSTransition>
              <div
                tw="lg:flex lg:items-center"
                className="secondNav"
                css={css`
                  @media (max-width: 1024px) {
                    transition: left 0.5s cubic-bezier(0.16, 1, 0.3, 1);
                    left: ${this.state?.sidemenuActive ? 0 : '-100%'};
                    position: absolute;
                    ${tw`bg-blue-black`}
                    width: 320px;
                    height: 200vh;
                    top: 68px;
                    padding-bottom: 16px;
                    a,
                    .subnav > a {
                      display: block;
                      padding: 6px 16px;
                    }
                    ul {
                      position: static;
                      padding-bottom: 0;
                      margin: 12px 0;
                      li {
                        list-style: disc outside none;
                        margin-left: 42px;
                        a {
                          padding-left: 0;
                          min-width: 0;
                        }
                      }
                    }
                    .subnav {
                    }
                  }
                `}
              >
                <div
                  className="subnav"
                  onMouseOver={() => {
                    if (window.innerWidth < 1024) {
                      return;
                    }
                    this.setState({
                      layananActive: false,
                      peraturanActive: true,
                      eventActive: false,
                      sidemenuActive: false,
                    });
                  }}
                  onMouseLeave={() => {
                    if (window.innerWidth < 1024) {
                      return;
                    }
                    this.setState({
                      layananActive: false,
                      peraturanActive: false,
                      eventActive: false,
                      sidemenuActive: false,
                    });
                  }}
                  onClick={() =>
                    this.setState({
                      peraturanActive: !this.state?.peraturanActive,
                      layananActive: false,
                      eventActive: false,
                    })
                  }
                >
                  <a href="/" onClick={e => e.preventDefault()}>
                    Peraturan{' '}
                    <span
                      className={
                        this.state?.peraturanActive
                          ? 'icon icon-arrow-drop-up'
                          : 'icon icon-arrow-drop-down'
                      }
                    ></span>
                  </a>
                  <CSSTransition
                    in={this.state?.peraturanActive}
                    timeout={300}
                    classNames="dropdown"
                    unmountOnExit
                  >
                    <ul key={this.state?.peraturanActive?.toString()}>
                      <li>
                        <a href="https://www.easybiz.id/peraturan/peraturan-oss-online-single-submission/">
                          Peraturan OSS (Online Single Submission)
                        </a>
                      </li>
                      <li>
                        <a href="https://www.easybiz.id/peraturan/peraturan-pendirian-badan-usaha/">
                          Peraturan Pendirian Badan Usaha
                        </a>
                      </li>
                      <li>
                        <a href="https://www.easybiz.id/peraturan/peraturan-perizinan-dan-pendaftaran/">
                          Peraturan Perizinan dan Pendaftaran
                        </a>
                      </li>
                      <li>
                        <a href="https://www.easybiz.id/peraturan/peraturan-kbli-dan-dni/">
                          Peraturan KBLI dan DNI
                        </a>
                      </li>
                      <li>
                        <a href="https://www.easybiz.id/peraturan/peraturan-terkait-lainnya/">
                          Peraturan terkait Lainnya
                        </a>
                      </li>
                    </ul>
                  </CSSTransition>
                </div>
                <Link to="/tentang-kami/">Tentang Kami</Link>
                <Link to="/artikel">Artikel</Link>
                <div
                  className="subnav"
                  onMouseOver={() => {
                    if (window.innerWidth < 1024) {
                      return;
                    }
                    this.setState({
                      layananActive: false,
                      eventActive: true,
                      peraturanActive: false,
                      sidemenuActive: false,
                    });
                  }}
                  onMouseLeave={() => {
                    if (window.innerWidth < 1024) {
                      return;
                    }
                    this.setState({
                      layananActive: false,
                      peraturanActive: false,
                      eventActive: false,
                      sidemenuActive: false,
                    });
                  }}
                  onClick={() => {
                    this.setState({
                      eventActive: !this.state?.eventActive,
                      layananActive: false,
                      peraturanActive: false,
                    });
                  }}
                >
                  <a href="/" onClick={e => e.preventDefault()}>
                    Program{' '}
                    <span
                      className={
                        this.state?.eventActive
                          ? 'icon icon-arrow-drop-up'
                          : 'icon icon-arrow-drop-down'
                      }
                    ></span>
                  </a>
                  <CSSTransition
                    in={this.state?.eventActive}
                    timeout={300}
                    classNames="dropdown"
                    unmountOnExit
                  >
                    <ul key={this.state?.eventActive?.toString()}>
                      <li>
                        <Link to="/events/">Events</Link>
                      </li>
                      <li>
                        <a href="https://www.easybiz.id/peraturan/materi-workshop/">
                          Workshop by Easybiz
                        </a>
                      </li>
                    </ul>
                  </CSSTransition>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
